import { Logo } from '../assets/Logos';
import { CrossLogo, MenuLogo } from '../assets/SVG';
import MenuHeaderButton from './MenuHeaderButton';

interface MHprops {
  callback: () => void;
  showMenu: boolean;
}

const MenuHeader = (props: MHprops) => {
  return (
    <div
      className="bg-app-black2 h-[80px] w-full flex justify-between items-center pl-[30px] pr-[27.5px] \\
                      md:pl-[43px] md:pr-[42px] xl:pl-[200px] xl:pr-[200px]"
    >
      <Logo className="w-[39px]" />
      <div className="flex h-full items-center">
        <div className="hidden md:flex text-app-white">
          <MenuHeaderButton link="home" text="Home" />
          <MenuHeaderButton link="" text="News" />
          <MenuHeaderButton link="" text="Shop" />
          <MenuHeaderButton link="" text="Gallery" />
          <MenuHeaderButton link="" text="Contacts" />
        </div>
        <button className="h-min md:hidden" onClick={props.callback}>
          {!props.showMenu ? (
            <MenuLogo className="w-[25px] h-[15px] text-white" />
          ) : (
            <CrossLogo className="w-[20px] h-[20px] text-white" />
          )}
        </button>
      </div>
    </div>
  );
};

export default MenuHeader;
