import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Menu from './pages/Menu';
import UnderConstruction from './pages/UnderConstruction';

const router = createBrowserRouter([
  {
    path: '/',
    element: <UnderConstruction />,
  },
  {
    path: '/home',
    element: <Menu />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
