import { Link } from 'react-router-dom';

interface MHBProps {
  link: string;
  text: string;
}

const MenuHeaderButton = (props: MHBProps) => {
  return (
    <Link to={props.link} className="font-bold ml-[15px] xl:ml-[45px] text-base">
      {props.text}
    </Link>
  );
};

export default MenuHeaderButton;
