import { FacebookLogo, InstagramLogo, YoutubeLogo } from '../components/assets/Logos';

const UnderConstruction = () => {
  return (
    <div className="w-screen h-screen bg-app-black font-openSans text-app-white pt-[165px]">
      <div className="flex justify-center w-screen">
        <p
          className="w-[400px] h-[166px] text-[48px] font-ubuntu font-bold text-center leading-[55px] \\
                    md:w-[500px] md:text-[64px] md:leading-[74px]"
        >
          EM CONSTRUÇÃO
        </p>
      </div>

      <p
        className="w-screen flex justify-center items-center font-normal text-[16px] tracking-[0.1em] \\
                      xl:text-[20px]"
      >
        O SITE DO VASCO ESTÁ QUASE PRONTO
      </p>

      <div className="absolute bottom-[26px] flex justify-center md:bottom-[39px]">
        <div className="w-screen">
          <div
            className="flex justify-center items-center mb-[19.96px] space-x-[14.67px] md:space-x-[20.42px] \\
                            xl:mb-[29px]"
          >
            <a href="https://www.facebook.com/SC-Vasco-da-Gama-Oficial-245610468856099/" target="_blank">
              <FacebookLogo className="w-[53.5px] md:w-[107px]" />
            </a>
            <a href="https://www.instagram.com/s.c.vascodagama/" target="_blank">
              <InstagramLogo className="w-[52px] md:w-[104px]" />
            </a>
            <a href="https://www.youtube.com/@sportingclubevascodagama1559" target="_blank">
              <YoutubeLogo className="w-[62.5px] md:w-[125px]" />
            </a>
          </div>

          <p
            className="w-full mb-[19px] font-DMSans font-bold text-center text-[20px] text-app-white2 \\
                        md:text-[32px] md:mb-[68px] xl:mb-[58px]"
          >
            #UMPORTODOSTODOSPELOVASCO
          </p>

          <p
            className="h-[57px] w-full text-center text-[14px] tracking-[0.1em] flex justify-center items-center \\
                       md:justify-center md:space-x-1 md:text-[16px] md:h-[44px]"
          >
            Copyright © {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
