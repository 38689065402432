import { ContactsLogo, GalleryLogo, HomeLogo, NewsLogo, ShopLogo } from '../assets/SVG';
import MenuButton from './MenuButton';

interface MOProps {
  className: string;
  animationCallback: () => void;
}

const MenuOptions = (props: MOProps) => {
  return (
    <div
      className={`md:hidden bg-app-black2 overflow-x-hidden ${props.className}`}
      onAnimationEnd={props.animationCallback}
    >
      <div className="pt-[62px]">
        <MenuButton icon={<HomeLogo className="w-[18px] h-[20px] text-app-red" />} link="home" text="Home" />
        <MenuButton icon={<NewsLogo className="w-[20px] h-[18px] text-app-red" />} link="" text="News" />
        <MenuButton icon={<ShopLogo className="w-[20px] h-[20px] text-app-red" />} link="" text="Shop" />
        <MenuButton icon={<GalleryLogo className="w-[20px] h-[20px] text-app-red" />} link="" text="Gallery" />
        <MenuButton icon={<ContactsLogo className="w-[20px] h-[16px] text-app-red" />} link="" text="Contacts" />
      </div>
    </div>
  );
};

export default MenuOptions;
