import { Link } from 'react-router-dom';
import { RightArrowLogo } from '../assets/SVG';

interface MBprops {
  icon: JSX.Element;
  text: string;
  link: string;
}

const MenuButton = (props: MBprops) => {
  return (
    <div className="w-full pr-[38.63px] pl-[29px] mb-[30px]">
      <Link to={props.link} className="w-full flex place-content-between">
        <div className="flex space-x-[16]">
          <div className=" h-[26px] w-[50px] flex justify-center items-center">{props.icon}</div>
          <div className="h-[36px] text-app-white text-[20px] leading-[26px] font-bold">{props.text}</div>
        </div>
        <RightArrowLogo className="w-[9px] h-[21px] text-app-white" />
      </Link>
    </div>
  );
};

export default MenuButton;
