import logo from '../../assets/logo-scvg.png';
import facebook from '../../assets/facebook.png';
import instagram from '../../assets/instagram.png';
import youtube from '../../assets/youtube.png';

interface ImageProps {
  className: string;
}

const Logo = (props: ImageProps) => {
  return <img src={logo} alt="Logo" className={props.className} />;
};

const FacebookLogo = (props: ImageProps) => {
  return <img src={facebook} alt="FacebookLogo" className={props.className} />;
};

const InstagramLogo = (props: ImageProps) => {
  return <img src={instagram} alt="InstagramLogo" className={props.className} />;
};

const YoutubeLogo = (props: ImageProps) => {
  return <img src={youtube} alt="YoutubeLogo" className={props.className} />;
};

export { FacebookLogo, InstagramLogo, Logo, YoutubeLogo };
