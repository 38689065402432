import { useEffect, useState } from 'react';
import MenuHeader from '../components/Menu/MenuHeader';
import MenuOptions from '../components/Menu/MenuOptions';

const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [animating, setAnimating] = useState(false);

  const changeShowMenu = () => {
    setShowMenu(!showMenu);
    setAnimating(true);
  };

  const changeAnimating = () => {
    setAnimating(false);
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 768 && showMenu) {
        setShowMenu(false);
        setAnimating(false);
      }
    }

    window.addEventListener('resize', handleResize);
  });

  return (
    <div className="flex flex-col h-screen items-end">
      <MenuHeader callback={changeShowMenu} showMenu={showMenu} />
      {(showMenu || animating) && (
        <MenuOptions
          animationCallback={changeAnimating}
          className={`${showMenu ? 'animate-slide' : 'animate-slide_back w-[0%]'}`}
        />
      )}
      {!showMenu && !animating && <div>Menu fechado</div>}
    </div>
  );
};

export default Menu;
